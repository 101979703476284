import { useAuth, withAuth } from 'react-oidc-context'
import { Button, Dropdown, Navbar } from 'react-daisyui'
import { useRef, useState } from 'react'

import { useClickAway } from 'react-use'
import plantypusLogo from '../assets/img/plantypus_logo.png'
import emptyProfilePicture from '../assets/img/empty-profile-picture.png'

function Header(): JSX.Element {
  const auth = useAuth()
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useClickAway(ref, () => setOpen(false))

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
    default:
      break
  }

  if (auth.isLoading) {
    return <div>Loading...</div>
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }
  if (!auth.isAuthenticated) {
    const originPath = window.location.pathname
    auth.signinRedirect({
      redirect_uri: process.env.REACT_APP_FRONTEND_URI?.concat(originPath),
    })
    return <div>Redirecting...</div>
  }
  window.history.replaceState({}, document.title, window.location.pathname)

  return (
    <Navbar className="flex w-full bg-blue-main  flex-row justify-between items-center p-5">
      <div className="flex flex-row items-center text-white font-light text-2xl xl:text-3xl">
        <a href="/dashboard" className="mx-3">
          <img src={plantypusLogo} alt="Plantypus Logo" width={45} height={45} />
        </a>
        <a href="/dashboard">
          <p style={{ fontFamily: 'Quicksand', fontWeight: 500, letterSpacing: 2 }}>PLANTYPUS</p>
        </a>
      </div>
      <div
        className="flex flex-row gap-2 justify-around items-center space-x-7 text-white font-light text-base
        xl:text-xl"
      >
        <div>{auth.user?.profile?.name}</div>
        <Dropdown end>
          <Button
            tag="label"
            tabIndex={0}
            color="ghost"
            className="avatar"
            shape="circle"
            onClick={() => setOpen(!open)}
          >
            <div className="w-10 rounded-full">
              <img alt="profile" src={emptyProfilePicture} />
            </div>
          </Button>
          <Dropdown.Menu
            className={`text-black bg-gray-100 w-52 menu-sm mt-3 z-[1] p-2 ${open ? '' : 'hidden'}`}
          >
            <li>
              <a
                href={`${process.env.REACT_APP_OIDC_AUTHORITY}/account/#/personal-info`}
                className="justify-between"
              >
                Profile
              </a>
            </li>
            <Dropdown.Item>
              <button
                type="button"
                onClick={() => {
                  auth.removeUser()
                  auth.signoutRedirect()
                }}
              >
                Logout
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Navbar>
  )
}

export default withAuth(Header)
