import { createSlice } from '@reduxjs/toolkit'

interface ModalState {
  deviceLibraryModal: boolean
  createDeviceModal: boolean
}

const initialState: ModalState = {
  deviceLibraryModal: false,
  createDeviceModal: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openDeviceLibraryModal: (state: ModalState) => {
      state.deviceLibraryModal = true
      state.createDeviceModal = false
    },
    closeDeviceLibraryModal(state: ModalState) {
      state.deviceLibraryModal = false
    },
    openCreateDeviceModal(state: ModalState) {
      state.createDeviceModal = true
      state.deviceLibraryModal = false
    },
    closeCreateDeviceModal(state: ModalState) {
      state.createDeviceModal = false
    },
  },
})

export const {
  openDeviceLibraryModal,
  closeDeviceLibraryModal,
  openCreateDeviceModal,
  closeCreateDeviceModal,
} = modalSlice.actions
export default modalSlice.reducer
