import axios from 'axios'
import { useAuth } from 'react-oidc-context'
import { Device } from '../models/device.model'
import PortInCanvasFactory from '../shared/PortInCanvasFactory'
import { CanvasItem } from '../models/canvasItem.model'
import { CanvasObjects } from '../components/Editor.component'
import { CanvasConnection } from '../models/canvasConnection.model'
import CreateDeviceFormPort from '../shared/models/CreateDeviceFormPort.interface'

const useDeviceService = (): {
  getAllDevices: () => Promise<Device[]>
  addDeviceToCanvas: (device: Device, projectId: string) => Promise<void>
  createDevice: (
    manufacturer: string,
    model: string,
    categories: string[],
    defaultWidth: number,
    defaultHeight: number,
    inputPorts: CreateDeviceFormPort[],
    outputPorts: CreateDeviceFormPort[],
    hybridPorts: CreateDeviceFormPort[]
  ) => Promise<void>
} => {
  const auth = useAuth()

  const getAllDevices = async (): Promise<Device[]> => {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/device/`

    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    const result = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: authorizationHeader,
      },
    })
    return result.data as Device[]
  }

  const createDevice = async (
    manufacturer: string,
    model: string,
    categories: string[],
    defaultWidth: number,
    defaultHeight: number,
    inputPorts: CreateDeviceFormPort[],
    outputPorts: CreateDeviceFormPort[],
    hybridPorts: CreateDeviceFormPort[]
  ): Promise<void> => {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/device/`
    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    await axios({
      method: 'post',
      url,

      data: {
        manufacturer,
        model,
        categories,
        ports: [...inputPorts, ...outputPorts, ...hybridPorts],
        width: defaultWidth,
        height: defaultHeight,
      },
      headers: { 'Content-type': 'application/json', Authorization: authorizationHeader },
    }).catch((error) => {
      console.error(error)
    })
  }

  const addDeviceToCanvas = async (device: Device, projectId: string): Promise<void> => {
    const headlessCanvas = new draw2d.HeadlessCanvas()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canvasDevice = new DeviceInCanvas({
      x: 100,
      y: 100,
      width: device.width,
      height: device.height,
      color: 'rgba(27,27,27,1)',
      bgColor: 'rgba(160,160,160,1)',
      userData: {
        manufacturer: device.manufacturer,
        model: device.model,
      },
    })

    const { manufacturer, model } = device
    const label = new draw2d.shape.basic.Label({ text: `${manufacturer}\n${model}` })
    label.setStroke(0)
    canvasDevice.add(label, new draw2d.layout.locator.CenterLocator())

    device.ports.forEach((port) => {
      const portInCanvas = PortInCanvasFactory(port)
      canvasDevice.addPort(portInCanvas)
    })

    headlessCanvas.add(canvasDevice, 200, 200)
    const writer = new draw2d.io.json.Writer()
    writer.marshal(headlessCanvas, async (canvasItems: CanvasItem[]) => {
      const canvasUpdateDto = {
        projectId,
        items: [],
        connections: [],
      } as CanvasObjects
      canvasItems.forEach((canvasObject) => {
        if (canvasObject.type === 'draw2d.Connection') {
          canvasUpdateDto.connections.push(canvasObject as unknown as CanvasConnection)
        } else {
          canvasUpdateDto.items.push(canvasObject)
        }
      })

      const url = `${process.env.REACT_APP_BACKEND_URI}/v1/canvas/`
      const token = auth.user?.access_token
      console.log('token', token)
      const authorizationHeader = token ? `Bearer ${token}` : ''

      await axios({
        method: 'PUT',
        url,
        headers: {
          Authorization: authorizationHeader,
        },
        data: canvasUpdateDto,
      })
        .then(() => window.location.reload())
        .catch((error) => {
          throw error.response ? new Error(error.response.data.message) : error
        })
    })
  }

  return {
    getAllDevices,
    addDeviceToCanvas,
    createDevice,
  }
}

export default useDeviceService
