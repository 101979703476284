import { Modal } from 'react-daisyui'
import React, { useState } from 'react'
import useProjectService from '../../hooks/useProjectService'

interface createProjectModalProps {
  isOpen: boolean
  close: () => void
}

// eslint-disable-next-line react/function-component-definition
const CreateProjectModal: React.FC<createProjectModalProps> = ({ isOpen, close }) => {
  const [projectName, setProjectName] = useState('')
  const { createProject } = useProjectService()

  return (
    <Modal className="bg-blue-main max-w-5xl absolute top-20" open={isOpen} backdrop>
      <Modal.Header className="flex flex-row items-start text-white">Add a project</Modal.Header>
      <Modal.Body>
        <form>
          <div className=" bg-blue-main">
            <div className="flex flex-col items-start">
              <div className="p-8 px-6 w-full max-w-screen-xl h-96 align-middle bg-white rounded-md">
                <div className="flex flex-col sm:max-w-max">
                  <div className="flex flex-row justify-between pb-6">
                    <p className="pr-10">Project Name</p>
                    <input
                      onChange={(event) => setProjectName(event.target.value)}
                      className="p-1 text-base xl:text-lg font-light rounded-md border border-1 focus:ring-1
                            border-grey-dark bg-grey-light placeholder-grey-dark focus:ring-blue-third ml-5"
                      type="text"
                      id="projectName"
                      name="projectName"
                      placeholder="Project Name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Actions className="sm:flex sm:flex-row sm:justify-between bg-blue-main">
        <button
          type="button"
          className="inline-flex justify-center w-full sm:w-auto font-light text-white border
                  border-transparent hover:text-grey-dark"
          onClick={close}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            createProject(projectName)
          }}
          type="button"
          value="submit"
          className="inline-flex justify-center w-full sm:w-auto font-light text-white border
                  border-transparent hover:text-grey-dark"
        >
          Add
        </button>
      </Modal.Actions>
    </Modal>
  )
}

export default CreateProjectModal
