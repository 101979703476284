import { useAuth } from 'react-oidc-context'
import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useProjectService = (): { createProject: (projectName: string) => Promise<void> } => {
  const auth = useAuth()

  const createProject = async (projectName: string): Promise<void> => {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/project/`

    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    await axios({
      method: 'post',
      url,
      data: {
        name: projectName,
      },
      headers: {
        'Content-type': 'application/json',
        Authorization: authorizationHeader,
      },
    })
      .then((res) => {
        const projectData = res.data as {
          name: string
          uuid: string
        }
        window.location.href = `/project/${projectData.uuid}`
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return {
    createProject,
  }
}

export default useProjectService
