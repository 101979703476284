import { useState } from 'react'

const useModal = (): { isOpen: boolean; close: () => void; open: () => void } => {
  const [isOpen, setIsOpen] = useState(false)

  const open = (): void => setIsOpen(true)
  const close = (): void => setIsOpen(false)

  return {
    isOpen,
    open,
    close,
  }
}

export default useModal
