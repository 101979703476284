import 'jquery-ui-bundle'
import 'jquery-ui-bundle/jquery-ui.css'

import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import jquery from 'jquery'
import { AuthProvider } from 'react-oidc-context'

import { Provider } from 'react-redux'
import Landing from './pages/landing'
import Project from './pages/project'
import Dashboard from './pages/dashboard'
import store from './store/store'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = jquery

function App(): JSX.Element {
  const oidcConfig = {
    authority: process.env.REACT_APP_OIDC_AUTHORITY,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  }

  return (
    <Provider store={store}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <div className="App">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/project/:projectId" element={<Project />} />
              <Route path="*" element={<Landing />} />
            </Routes>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  )
}

export default App
