import { useEffect, useState } from 'react'
import { Modal } from 'react-daisyui'
import { PortType } from '../../enums/portType.enum'
import { SignalInterface } from '../../enums/signalInterface.enum'
import CreateDeviceFormPort from '../../shared/models/CreateDeviceFormPort.interface'
import useDeviceService from '../../hooks/useDeviceService'
import { Port } from '../../models/port.model'
import PortInCanvasFactory from '../../shared/PortInCanvasFactory'
import { useAppDispatch } from '../../store/hooks/hooks'
import { closeCreateDeviceModal } from '../../store/slices/modal.slice'

interface CreateDeviceModalProps {
  isOpen: boolean
}

function CreateDeviceModal({ isOpen }: CreateDeviceModalProps): JSX.Element {
  const deviceService = useDeviceService()
  const [manufacturer, setManufacturer] = useState('')
  const [model, setModel] = useState('')
  const [categories, setCategories] = useState<string[]>([])
  const [defaultWidth, setDefaultWidth] = useState(100)
  const [defaultHeight, setDefaultHeight] = useState(100)
  const [inputPorts, setInputPorts] = useState<CreateDeviceFormPort[]>([])
  const [outputPorts, setOutputPorts] = useState<CreateDeviceFormPort[]>([])
  const [hybridPorts, setHybridPorts] = useState<CreateDeviceFormPort[]>([])
  const [preview, setPreview] = useState<draw2d.Canvas | undefined>(undefined)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const _preview = preview || new draw2d.Canvas('device_preview')
    _preview.clear()
    _preview.setDimension(defaultWidth + 50, defaultHeight + 50)
    _preview.installEditPolicy(new draw2d.policy.canvas.ReadOnlySelectionPolicy())

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canvasDevice = new DeviceInCanvas({
      x: 25,
      y: 25,
      width: defaultWidth,
      height: defaultHeight,
      color: 'rgba(27,27,27,1)',
      bgColor: 'rgba(160,160,160,1)',
      userData: {
        manufacturer,
        model,
      },
    })

    const label = new draw2d.shape.basic.Label({ text: `${manufacturer}\n${model}` })
    label.setStroke(0)
    canvasDevice.add(label, new draw2d.layout.locator.CenterLocator())

    inputPorts.forEach((createDeviceFormPort) => {
      const port = new Port({
        type: createDeviceFormPort.type,
        signalName: createDeviceFormPort.signal_name,
        signalShortName: createDeviceFormPort.signal_short_name,
        signalInterface: createDeviceFormPort.signal_interface,
      })
      const portInCanvas = PortInCanvasFactory(port)
      canvasDevice.addPort(portInCanvas)
    })

    outputPorts.forEach((createDeviceFormPort) => {
      const port = new Port({
        type: createDeviceFormPort.type,
        signalName: createDeviceFormPort.signal_name,
        signalShortName: createDeviceFormPort.signal_short_name,
        signalInterface: createDeviceFormPort.signal_interface,
      })
      const portInCanvas = PortInCanvasFactory(port)
      canvasDevice.addPort(portInCanvas)
    })

    hybridPorts.forEach((createDeviceFormPort) => {
      const port = new Port({
        type: createDeviceFormPort.type,
        signalName: createDeviceFormPort.signal_name,
        signalShortName: createDeviceFormPort.signal_short_name,
        signalInterface: createDeviceFormPort.signal_interface,
      })
      const portInCanvas = PortInCanvasFactory(port)
      canvasDevice.addPort(portInCanvas)
    })

    _preview.add(canvasDevice)
    setPreview(_preview)
  }, [
    manufacturer,
    model,
    categories,
    defaultWidth,
    defaultHeight,
    inputPorts,
    outputPorts,
    hybridPorts,
  ])

  const toCategoryString = (_categories: string[]): string =>
    _categories.map((category) => category).join(', ')

  const addInput = (): void => {
    inputPorts.push({
      type: PortType.SDI_INPUT,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.SDI,
    })
    setInputPorts([...inputPorts])
  }

  const addOutput = (): void => {
    outputPorts.push({
      type: PortType.SDI_OUTPUT,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.SDI,
    })
    setOutputPorts([...outputPorts])
  }

  const addHybridPort = (): void => {
    hybridPorts.push({
      type: PortType.ETHERNET,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.ETHERNET,
    })
    setHybridPorts([...hybridPorts])
  }

  const renderInputPort = (i: number): JSX.Element => {
    const port = inputPorts[i]

    return (
      <div key={`createNewDeviceForm-input${i}`} className="flex flex-col mb-12">
        <select
          className="createNewDeviceForm-input-interface border border-grey-dark
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case 'sdi':
                port.type = PortType.SDI_INPUT
                break
              case 'hdmi':
                port.type = PortType.HDMI_INPUT
                break
              case SignalInterface.KLINKE_3_5:
                port.type = PortType.KLINKE_3_5_INPUT
                break
              case SignalInterface.KLINKE_6_3:
                port.type = PortType.KLINKE_6_3_INPUT
                break
              case SignalInterface.XLR:
                port.type = PortType.XLR_INPUT
                break
              default:
                port.type = PortType.SDI_INPUT
                break
            }
            setInputPorts([...inputPorts])
          }}
        >
          <option value={SignalInterface.SDI}>SDI</option>
          <option value={SignalInterface.HDMI}>HDMI</option>
          <option value={SignalInterface.KLINKE_3_5}>Klinke 3.5mm</option>
          <option value={SignalInterface.KLINKE_6_3}>Klinke 6.3mm</option>
          <option value={SignalInterface.XLR}>XLR</option>
        </select>
        <div className="flex flex-row">
          <input
            type="text"
            className="createNewDeviceForm-input-signal-short flex-grow border border-grey-dark
                              rounded-md bg-grey-light p-1 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
            name="signal-short"
            placeholder="Set signal short name"
            value={port.signal_short_name}
            onChange={(e) => {
              port.signal_short_name = e.target.value
              setInputPorts([...inputPorts])
            }}
          />
          <input
            type="button"
            value="—"
            className="bg-transparent cursor-pointer font-light hover:text-grey-dark rounded-md px-2"
            onClick={() => {
              const _inputPorts = inputPorts.filter((item) => inputPorts.indexOf(item) !== i)
              setInputPorts(_inputPorts)
            }}
          />
        </div>
      </div>
    )
  }

  const renderOutputPort = (i: number): JSX.Element => {
    const port = outputPorts[i]

    return (
      <div key={`createNewDeviceForm-output${i}`} className="flex flex-col mb-12">
        <select
          className="createNewDeviceForm-output-interface border border-grey-dark
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case SignalInterface.SDI:
                port.type = PortType.SDI_OUTPUT
                break
              case SignalInterface.HDMI:
                port.type = PortType.HDMI_OUTPUT
                break
              case SignalInterface.KLINKE_3_5:
                port.type = PortType.KLINKE_3_5_OUTPUT
                break
              case SignalInterface.KLINKE_6_3:
                port.type = PortType.KLINKE_6_3_OUTPUT
                break
              case SignalInterface.XLR:
                port.type = PortType.XLR_OUTPUT
                break
              default:
                port.type = PortType.SDI_OUTPUT
                break
            }
            setOutputPorts([...outputPorts])
          }}
        >
          <option value={SignalInterface.SDI}>SDI</option>
          <option value={SignalInterface.HDMI}>HDMI</option>
          <option value={SignalInterface.KLINKE_3_5}>Klinke 3.5mm</option>
          <option value={SignalInterface.KLINKE_6_3}>Klinke 6.3mm</option>
          <option value={SignalInterface.XLR}>XLR</option>
        </select>
        <div className="flex flex-row">
          <input
            type="text"
            className="createNewDeviceForm-output-signal-short flex-grow border border-grey-dark
                              rounded-md bg-grey-light p-1 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
            name="signal-short"
            placeholder="Set signal short name"
            onChange={(e) => {
              port.signal_short_name = e.target.value
              setOutputPorts([...outputPorts])
            }}
            value={port.signal_short_name}
          />
          <input
            type="button"
            value="—"
            className="bg-transparent cursor-pointer font-light hover:text-grey-dark rounded-md px-2"
            onClick={() => {
              const _outputPorts = outputPorts.filter((item) => outputPorts.indexOf(item) !== i)
              setOutputPorts(_outputPorts)
            }}
          />
        </div>
      </div>
    )
  }

  const renderHybridPorts = (i: number): JSX.Element => {
    const port = hybridPorts[i]

    return (
      <div key={`createNewDeviceForm-hybridPort${i}`} className="flex flex-col mb-12">
        <select
          className="createNewDeviceForm-hybridPort-interface border border-grey-dark
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case SignalInterface.ETHERNET:
                port.type = PortType.ETHERNET
                break
              default:
                port.type = PortType.ETHERNET
                break
            }
            setHybridPorts([...hybridPorts])
          }}
        >
          <option value={SignalInterface.ETHERNET}>Ethernet</option>
        </select>
        <div className="flex flex-row">
          <input
            type="text"
            className="createNewDeviceForm-hybridPort-signal-short flex-grow border border-grey-dark
                              rounded-md bg-grey-light p-1 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
            name="signal-short"
            placeholder="Set signal short name"
            onChange={(e) => {
              port.signal_short_name = e.target.value
              setHybridPorts([...hybridPorts])
            }}
            value={port.signal_short_name}
          />
          <input
            type="button"
            value="—"
            className="bg-transparent cursor-pointer font-light hover:text-grey-dark rounded-md px-2"
            onClick={() => {
              const _hybridPorts = hybridPorts.filter((item) => hybridPorts.indexOf(item) !== i)
              setHybridPorts(_hybridPorts)
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <Modal
      className="bg-blue-main max-w-5xl absolute top-10 px-10 overflow-hidden"
      open={isOpen}
      backdrop
    >
      <Modal.Header className="flex flex-row items-start text-white font-medium text-2xl mb-0">
        Add a device
      </Modal.Header>
      <Modal.Body className="flex-1 overflow-y-auto w-full">
        <div className="flex flex-row rounded-md my-6 max-w-screen-xl w-full">
          <div
            className="flex flex-col bg-white w-full py-8 text-black rounded-md px-6
               overflow-y-scroll max-h-[calc(100vh-300px)]"
          >
            <form action="#" id="createNewDeviceForm">
              <div className="">
                <div className="flex items-start flex-col">
                  <div
                    className="flex flex-row text-black rounded-md align-middle max-w-screen-xl
                  w-full h-full"
                  >
                    <div className="flex flex-col flex-grow">
                      <div className="flex flex-row justify-between">
                        <div>Manufacturer</div>
                        <div className="w-2/4">
                          <input
                            type="text"
                            id="createNewDeviceForm-manufacturer"
                            value={manufacturer}
                            onChange={(e) => {
                              setManufacturer(e.target.value)
                            }}
                            name="manufacturer"
                            placeholder="Set manufacturer"
                            className="w-full border border-grey-dark
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>Model</div>
                        <div className="w-2/4">
                          <input
                            type="text"
                            id="createNewDeviceForm-model"
                            name="model"
                            value={model}
                            onChange={(e) => {
                              setModel(e.target.value)
                            }}
                            placeholder="Set model"
                            className="w-full border border-grey-dark
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>Categories (comma separated)</div>
                        <div className="w-2/4">
                          <input
                            type="text"
                            id="createNewDeviceForm-category"
                            name="category"
                            value={toCategoryString(categories)}
                            onChange={(e) => {
                              setCategories(e.target.value.split(', '))
                            }}
                            placeholder="Set category"
                            className="w-full border border-grey-dark
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div>Size</div>
                        <div className="w-2/4">
                          <p className="text-sm mb-1">Width</p>
                          <input
                            type="number"
                            id="createNewDeviceForm-defaultWidth"
                            name="defaultWidth"
                            value={defaultWidth}
                            onChange={(e) => {
                              setDefaultWidth(parseInt(e.target.value, 10))
                            }}
                            placeholder="default width"
                            className="border border-grey-dark
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                          />
                          <p className="text-sm mb-1">Height</p>
                          <input
                            type="number"
                            id="createNewDeviceForm-defaultHeight"
                            name="defaultHeight"
                            value={defaultHeight}
                            onChange={(e) => {
                              setDefaultHeight(parseInt(e.target.value, 10))
                            }}
                            placeholder="Set category"
                            className="border border-grey-dark
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                          />
                        </div>
                      </div>
                      <div className="flex flex-row justify-between pt-6">
                        <div>Inputs</div>
                        <div id="createNewDeviceForm-inputs" className="w-2/4">
                          {inputPorts.map((port, i) => renderInputPort(i))}
                        </div>
                      </div>
                      <input
                        type="button"
                        value="Add input"
                        onClick={addInput}
                        className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark rounded-md px-2 mb-12"
                      />
                      <div className="flex flex-row justify-between pt-6">
                        <div>Outputs</div>
                        <div id="createNewDeviceForm-outputs" className="w-2/4">
                          {outputPorts.map((port, i) => renderOutputPort(i))}
                        </div>
                      </div>
                      <input
                        type="button"
                        value="Add output"
                        className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark rounded-md px-2 mb-12"
                        onClick={addOutput}
                      />

                      <div className="flex flex-row justify-between pt-6">
                        <div>Bidirectional Ports</div>
                        <div id="createNewDeviceForm-bidirectional" className="w-2/4">
                          {hybridPorts.map((port, i) => renderHybridPorts(i))}
                        </div>
                      </div>
                      <input
                        type="button"
                        value="Add bidirectional port"
                        className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark rounded-md px-2 mb-12"
                        onClick={addHybridPort}
                      />
                    </div>
                    <div className="flex flex-col mx-8 w-1/4">
                      <h3 className="font-bold">Preview</h3>
                      <div className="overflow-x-scroll">
                        <div
                          id="device_preview"
                          className="relative"
                          style={{ width: defaultWidth + 40, height: defaultHeight + 40 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions className="bg-blue-main !m-0 sm:flex sm:flex-row sm:justify-between">
        <button
          onClick={() => dispatch(closeCreateDeviceModal())}
          type="button"
          className="w-full inline-flex justify-center bg-transparent border border-transparent text-white
                  font-light cursor-pointer hover:text-grey-dark sm:w-auto"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            deviceService
              .createDevice(
                manufacturer,
                model,
                categories,
                defaultWidth,
                defaultHeight,
                inputPorts,
                outputPorts,
                hybridPorts
              )
              .then(() => dispatch(closeCreateDeviceModal()))
          }}
          type="button"
          className="w-full inline-flex justify-center bg-transparent border border-transparent text-white
                  font-light cursor-pointer hover:text-grey-dark sm:w-auto"
        >
          Create the device
        </button>
      </Modal.Actions>
    </Modal>
  )
}

export default CreateDeviceModal
