import { useParams } from 'react-router-dom'
import { leftChevron1, plus } from '../assets/img'
import Editor from '../components/Editor.component'
import Header from '../components/header.component'
import DeviceLibraryModal from '../components/modals/deviceLibrary.modal'
import { useAppDispatch, useAppSelector } from '../store/hooks/hooks'
import { RootState } from '../store/store'
import { openDeviceLibraryModal } from '../store/slices/modal.slice'
import CreateDeviceModal from '../components/modals/createDevice.modal'

function Project(): JSX.Element {
  const params = useParams() as { projectId: string }

  const dispatch = useAppDispatch()
  const isDeviceLibraryModalOpen = useAppSelector(
    (state: RootState) => state.modal.deviceLibraryModal
  )
  const isCreateDeviceModalOpen = useAppSelector(
    (state: RootState) => state.modal.createDeviceModal
  )

  return (
    <div id="" className="h-screen flex flex-col" style={{ minWidth: '600px' }}>
      <Header />
      <div id="app" className="flex flex-1 overflow-hidden">
        <div
          id="sidebar"
          className="flex flex-1 flex-col h-full w-1/5 bg-blue-third overflow-y-auto"
        >
          <div
            id="sectionAddDevice"
            className="text-white bg-blue-secondary text-base xl:text-xl font-light
        p-5 flex flex-row justify-between items-center"
          >
            <p id="textAddDevice" className="">
              Add Device
            </p>
            <div id="device" className="">
              <button
                type="button"
                id="plus"
                className="h-4 xl:h-6 w-4 xl:w-6 filter invert cursor-pointer text-lg font-light"
                onClick={() => dispatch(openDeviceLibraryModal())}
              >
                <img alt="" src={plus} />
              </button>
              <DeviceLibraryModal isOpen={isDeviceLibraryModalOpen} projectId={params.projectId} />
              <CreateDeviceModal isOpen={isCreateDeviceModalOpen} />
            </div>
          </div>
          <div
            id="sectionProjectFiles"
            className="bg-blue-third text-white text-base xl:text-xl
        font-light p-5 flex items-start"
          >
            <div id="textProjectFiles" className="text-left">
              <p className="ml-5 mt-8 mb-8">
                Currently supported interfaces: <br /> <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#FFE366FF',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                Ethernet
                <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#3fe33f',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                SDI <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#e33f3f',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                HDMI <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#02b0f3',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                Phone Connector 3.5mm
                <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#8639e8',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                Phone Connector 6.3mm
                <br />
                <span
                  style={{
                    height: '15px',
                    width: '15px',
                    backgroundColor: '#C700FFFF',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '20px',
                  }}
                />
                XLR
                <br />
              </p>
              <h3 className="font-bold">Some Tips</h3>
              <p className="mt-5">
                Hold down shift and spin your scroll wheel to zoom in or out <br /> <br />
                Double click anywhere on the canvas to add a new label <br /> <br />
                Double click on a label to edit it <br /> <br />
                Double Click on a connection to add a label to it <br />
              </p>
            </div>
          </div>
          <div className="bg-blue-third flex items-center justify-end mt-auto">
            <img
              alt=""
              id="arrowLeft"
              className="h-4 xl:h-5 w-4 xl:w-5 m-5 filter invert cursor-pointer"
              src={leftChevron1}
            />
          </div>
        </div>
        <div id="content" className="flex flex-col w-4/5 bg-gray-300 shadow-2xl overflow-y-auto">
          <Editor projectId={params.projectId} />
        </div>
      </div>
    </div>
  )
}

export default Project
